function gtag() {
  // @ts-ignore
  window.dataLayer.push(arguments);
}

const trackElements = document.querySelectorAll('.js-track-click');

if (trackElements !== null) {
  Array.from(trackElements).forEach(element => {
    element.addEventListener('click', event => {      
      let target = element as HTMLElement;

      const { trackEventCategory, trackEventLabel } = target.dataset;

      // @ts-ignore
      gtag('event', 'click', {
        'event_category': trackEventCategory,
        'event_label': trackEventLabel,
        'value': '1',
      });
    });
  });
}